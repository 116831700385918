import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import { StaticImage } from 'gatsby-plugin-image'
import AboveFooterSection from '../components/AboveFooterSection'

import Layout from '../components/Layout'

const whyKafkaDevx = () => {
    const data = useStaticQuery(graphql`
        query WhyLensesSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "WhyLenses" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node
    const link1 = 'https://img.shields.io/docker/pulls/landoop/lenses.svg'

    /*
 Analytics -> https://analytics.google.com/analytics/web/#/report/content-pages/a61281743w95948335p193054606/_u.date00=20200801&_u.date01=20200909&_.goalOption=20&explorer-table.plotKeys=%5B%5D&_r.drilldown=analytics.pagePath:lenses.io~2Fwhy-lenses~2F/
 Read more -> https://analytics.google.com/analytics/web/#/report/content-event-events/a61281743w95948335p193054606/_u.date00=20200801&_u.date01=20200909&_.goalOption=20&explorer-segmentExplorer.segmentId=analytics.eventLabel&_r.drilldown=analytics.eventCategory:Nav%20-%20Why%20Lenses&explorer-table.plotKeys=%5B%5D/
 Organic   -> https://analytics.google.com/analytics/web/#/report/acquisition-channels/a61281743w95948335p193054606/_u.date00=20200101&_u.date01=20200909&_.goalOption=20&explorer-table.secSegmentId=analytics.pagePath&explorer-table.plotKeys=%5B%5D&explorer-table.advFilter=%5B%5B0,%22analytics.trafficChannel%22,%22PT%22,%22organic%22,0%5D,%5B0,%22analytics.pagePath%22,%22EQ%22,%22lenses.io~2Fwhy-lenses~2F%22,0%5D%5D/
 Download  -> https://analytics.google.com/analytics/web/#/report/content-pages/a61281743w95948335p193054606/_u.date00=20200101&_u.date01=20200909&_.goalOption=20&explorer-table.plotKeys=%5B%5D&explorer-table.rowCount=50&explorer-table.advFilter=%5B%5B0,%22analytics.pagePath%22,%22PT%22,%22lenses.io~2Fdownloads~2Flenses~2F%3Fs=why-lenses%22,0%5D%5D/
*/

    return (
        <Layout newsletter={false}>
            <HeaderSEO pageSEO={pageSEO} />
            <section className='py-5 mt-4 mobile-margin-reset'>
                <div className='container-2'>
                    <div
                        className=''
                        style={{ display: 'block', paddingTop: '150px', textAlign: 'center' }}>
                        <h1 style={{ fontSize: '42px', lineHeight: '60px', marginBottom: '20px' }}>
                            Why choose the leading developer <br /> experience for Kafka?
                        </h1>
                        <div className=''>
                            <p className='hero-description-dark'>Three benefits of using Lenses.</p>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap align-items-center'>
                        <div className='col-md-6 pt-5'>
                            {/*<p className='pt-5 number-format'>01.</p>*/}
                            <h1 className='pt-2 pb-1 mb-4 fw-500'>
                                Open up your data <br /> platform to more users
                            </h1>
                            <div className='pr-3'>
                                <p className=' pr-5'>
                                    As new data sources explode into being, developers need the
                                    freedom to explore and troubleshoot apps and data pipelines,
                                    limited only by the rules of your business:
                                </p>
                                <ul>
                                    <li>
                                        Search a universe of data across apps, pipelines, and
                                        environments.
                                    </li>
                                    <li>Catalog and share all your Kafka assets from one place.</li>
                                    <li>
                                        Integrate data streams with any object store, data lake, or
                                        repository.
                                    </li>
                                </ul>
                                {/*<p className='mb-1'>*/}
                                {/*    <b>Playtika</b> saves 300 working hours every day*/}
                                {/*</p>*/}
                                {/*<a*/}
                                {/*    className='primary-text pt-0'*/}
                                {/*    id='why-lenses-03-playtika-read-more'*/}
                                {/*    href='/customers/playtika/'*/}
                                {/*    role='button'>*/}
                                {/*    Read the story <i className='red-pointer-right'></i>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className='col-md-5 offset-md-1 pt-5'>
                            <div className='pt-5'>
                                <StaticImage
                                    className='img-fluid w-100 mx-auto d-block mt-5'
                                    src='../resources/images/new_homepage/platform_new_.webp'
                                    placeholder='tracedSVG'
                                    alt='Lenses - depploy apps'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-wrap align-items-center flex-column-reverse flex-sm-row pt-5 mt-5'>
                        <div className='col-md-5 pt-5'>
                            <div className='pt-5'>
                                <StaticImage
                                    className='img-fluid w-100 mx-auto d-block mt-5'
                                    src='../resources/images/new_homepage/security_new_.webp'
                                    placeholder='tracedSVG'
                                    alt='Lenses - data flows'
                                />
                            </div>
                        </div>

                        <div className='col-md-6 offset-md-1 pt-4'>
                            {/*<p className='pt-5 number-format'>02.</p>*/}
                            <h2 className='h1-like pt-2 pb-1 mb-4 fw-500'>
                                Prioritize data protection <br /> and security
                            </h2>
                            <div className='pr-3'>
                                <p className='pr-5'>
                                    Never compromise on regulatory compliance, or risk a security
                                    breach. The Lenses developer experience allows you to
                                </p>
                                <ul>
                                    <li>Set rules for sharing and collaboration.</li>
                                    <li>Mask sensitive fields and metadata.</li>
                                    <li>Apply role-based access across Kafka environments.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-wrap align-items-center pt-5 mt-5'>
                        <div className='col-md-6 pt-5 '>
                            {/*<p className='pt-5 number-format'>03.</p>*/}
                            <h2 className='h1-like pt-2 pb-1 mb-4 fw-500'>
                                Standardize coding <br /> processes and practices
                            </h2>
                            <div className='pr-3'>
                                <ul>
                                    <li>
                                        Build streaming pipelines and processors using simple SQL
                                        syntax.
                                    </li>
                                    <li>
                                        View and deploy your event-driven applications
                                        as-configuration, for good software governance.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-5 offset-md-1 pt-5'>
                            <div className='pt-5'>
                                <StaticImage
                                    className='img-fluid w-100 mx-auto d-block'
                                    src='../resources/images/new_homepage/code_new_.webp'
                                    placeholder='tracedSVG'
                                    alt='Lenses - data monitoring'
                                />
                            </div>
                        </div>

                        <div className='pb-5 mb-3 pt-3 align-items-center w-100'>
                            <div className='d-flex flex-wrap mt-5 mb-5'>
                                <div className='col-md-12 mt-5 text-center'>
                                    <h2 className='pt-2 paragraph-title'>
                                        Lenses is leading the data-ops revolution
                                        <a href='/apache-kafka-docker/'>
                                            <img
                                                src={link1}
                                                alt='fast data docker'
                                                className='pl-4'
                                            />
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <br />
                            <br />

                            <hr />
                        </div>
                    </div>

                    <div className='d-flex flex-wrap align-items-center pt-1 mt-1'>
                        <div className='col-md-5 pt-5'>
                            <div className='pt-5'>
                                <StaticImage
                                    className='img-fluid mx-auto d-block'
                                    src='../resources/images/best_rated_realtime_dataops_platform.png'
                                    placeholder='tracedSVG'
                                    style={{ maxWidth: '300px' }}
                                    alt='Lenses - data flows'
                                />
                            </div>
                        </div>

                        <div className='col-md-6 offset-md-1 pt-4 mt-3'>
                            <p className='pt-5 number-format'>Data Platform excellence</p>
                            <h2 className='h1-like pt-2 pb-1'>
                                100% <small>Satisfaction</small>
                            </h2>
                            <div className='pr-3'>
                                <p className='hero-description-dark pr-5 mb-0'>
                                    Lenses is the highest rated product for real-time stream
                                    analytics according to independent third party reviews.
                                </p>
                                <a
                                    className='primary-text pt-0'
                                    id='lenses-reviews'
                                    href='https://www.g2.com/products/lenses/reviews'
                                    rel='nofollow noopener noreferrer'
                                    target='_blank'
                                    role='button'>
                                    Read reviews <i className='red-pointer-right'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AboveFooterSection
                text={'The Data Platform for Open Source technologies'}
                ctaText={'Start now'}
                ctaLink='/start/'
            />
        </Layout>
    )
}

export default whyKafkaDevx
